import { isFunction } from 'lodash'
import { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { useTheme, NativeUiTheme } from './theme'

export type JssStyleFunction<StylesT, PropsT> = (theme: NativeUiTheme, props: PropsT) => StylesT

/**
 * The createUseStyles function is inspired by the react-jss function of the same name.
 * It is used to create a stylesheet for a react-native component and allows you to pass
 * a function as an argument that will receive the theme object along with any props that
 * are passed to the returned hook when it is called. It expects you to set the type for
 * the Styles and the Props (if they are used).
 *
 * @example
 *
 * const useStyles = createUseStyles<StylesType, PropsType>((theme, props) => ({
 *   header: {
 *     color: props.isValid ? theme.colors.green : theme.colors.gray500
 *   }
 * }))
 */
export function createUseStyles<StylesT extends StyleSheet.NamedStyles<StylesT>, PropsT = void>(
  stylesOrFn: StylesT | JssStyleFunction<StylesT, PropsT>
): (props: PropsT) => StylesT {
  return function useStyles(props: PropsT): StylesT {
    const theme: NativeUiTheme = useTheme()

    return useMemo<StylesT>(() => {
      if (isFunction(stylesOrFn)) {
        return StyleSheet.create<StylesT>(stylesOrFn(theme, props))
      }

      return StyleSheet.create<StylesT>(stylesOrFn)
    }, [props, theme])
  }
}
